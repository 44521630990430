import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import { Chart } from 'chart.js/auto';  // Import the Chart class
//import 'chart.js/auto';  // Import the Chart class

// Some stats TODO:
    // - Display how many distinct times someone views, e.g. per day or with gap of couple of hours
    //    This is to show revisits. Would be better on the client page next to them and an overall at the top an average
    //    but we only have a last login so we can only determine whether they have logged in again, not when, that would
    //    require system info, e.g. just look at their sign in and sum the number
    //
    // - On client page, log number of users with push notifications enabled. Also on artist page
    // However we don't actually store that info, only the token. So either take it from app.js which already got it
    // with the call await Notifications.requestPermissionsAsync(); or call getPermissionsAsync in authstaack when
    // we get the token
    //
    // - add summary of users with what version they are on, ilke in om
    //
    // But we really ought to exclude those older users so could do it from 25/3/25 when the new people started arriving


export default function ClientList() {

    const [clients, setClients] = useState([])
    const [showFromStart, setShowFromStart] = useState(true)  // Track whether we show from start or relaunch
    const relaunchDate = new Date('2025-03-30') // The relaunch date for filtering data


    useEffect(() => {
        getClients()
    }, [])

    const getClients = () => {
        axios.get(`${process.env.REACT_APP_API}/users/get-clients`)
        .then((res)=>{
            setClients(res.data)
        })
        .catch((error) => console.log(error))
    }

    function formatDate(date) {
        if (!date) {
            return 'Unknown'; // Handles cases where the date is null or undefined
        }

        if (date === 'Never') {
            return 'Never'; // Handles explicit 'Never' string
        }

        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-GB', options); // Adjust locale if needed
    }

    const totalClients = clients.length
    const validLoginsCount = clients.filter(client =>
        client.lastSignInTime &&
        client.lastSignInTime !== 'Unknown' &&
        client.lastSignInTime !== 'Never'
    ).length
    const validLoginsPercentage = ((validLoginsCount / totalClients) * 100).toFixed(2)

    return (
        <div style={{textAlign:'center', backgroundColor:'#f8f9fb', height: '100vh', overflowX:'auto'}}>
            <div style={{ margin: '1rem' }}>
                <h2>Number of Clients: {totalClients}</h2>
                <h3>
                    Clients that have logged in: {validLoginsCount} ({validLoginsPercentage}%)
                </h3>
                <button onClick={() => setShowFromStart(!showFromStart)}>
                    {showFromStart ? 'Show from Relauch Date (20th March 2025)' : 'Show from Start'}
                </button>
            </div>

            <hr style={{ width: '50%', margin: '2rem auto' }} />

            <ClientSignUpChart clients={clients} showFromStart={showFromStart} relaunchDate={relaunchDate} />

            <hr style={{ width: '50%', margin: '2rem auto' }} />

            <table id='artist-list-table' style={{width: 800, maxWidth:'90vw', margin: '1rem auto'}}>
                <thead>
                    <tr style={{border:'1px solid grey'}}>
                        <th></th>
                        <th><strong>Name</strong></th>
                        <th><strong>Email</strong></th>
                        <th><strong>Client Since</strong></th>
                        <th><strong>Last Logged In</strong></th>
                    </tr>
                </thead>
                <tbody>
                    {clients.map((client, i) => {
                        return <tr key={i}>
                            <td>
                                <img
                                    style={{height: 40, borderRadius:'50%', objectFit:'cover', objectPosition:'center'}}
                                    src={client.img}
                                    alt={client.firstName}
                                />
                            </td>
                            <td>{client.firstName} {client.lastName}</td>
                            <td>{client.email}</td>
                            <td>{formatDate(client.createdAt)}</td>
                            <td>{formatDate(client.lastSignInTime)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}


const ClientSignUpChart = ({ clients, showFromStart, relaunchDate }) => {
    const chartRef = useRef(null)

    useEffect(() => {
        try {
            // Destroy the previous chart instance if it exists
            if (chartRef.current) {
                chartRef.current.destroy()
            }

            // Get the canvas element
            const canvas = document.getElementById('clientSignUpChart');

            // Manipulate the data to get counts for each day
            const userCountsPerDay = {}
            const rollingPercentages = []
            const rolling5DayCounts = []

            const filteredClients = clients.filter(client => {
                const signupDate = new Date(client.createdAt)
                return showFromStart || signupDate >= relaunchDate
            })

            filteredClients.forEach(client => {
                const signupDate = new Date(client.createdAt)
                const formattedDate = signupDate.toLocaleDateString('en-GB') // Ensure 'dd/mm/yyyy' format
                userCountsPerDay[formattedDate] = (userCountsPerDay[formattedDate] || 0) + 1
            })

            // Helper function to parse 'dd/mm/yyyy' correctly
            const parseDate = (dateString) => {
                const [day, month, year] = dateString.split('/').map(Number)
                return new Date(year, month - 1, day) // Note: month is 0-indexed in JS
            }
            // Calculate rolling 30-day login percentage
            const sortedDates = Object.keys(userCountsPerDay).sort((a, b) => parseDate(a) - parseDate(b))

            const dawnOfClientsDate =
                showFromStart ?
                    (sortedDates > 0 ? parseDate(sortedDates[1]) : null) // using 1 because 0 might be 'Invalid Date'
                    : relaunchDate

            // NOTE: since app sign ups involve logging in this will skew the results so perhaps should be
            // excluded and instead only signups post create date be included. Having said that, unless
            // there is a steady stream of sign ups, the percentage will drop if those old users don't log
            // in again
            sortedDates.forEach((currentDate, index) => {
                const endDate = parseDate(currentDate)
                const startDate = new Date(endDate);
                startDate.setDate(endDate.getDate() - 29)

                const recentClients = clients.filter(client => {
                    const signupDate = new Date(client.createdAt)
                    return signupDate >= startDate && signupDate <= endDate
                })

                const totalClientsToThisPoint = clients.filter(client => {
                    const signupDate = new Date(client.createdAt)
                    return signupDate >= dawnOfClientsDate && signupDate <= endDate
                })

                const recentLogins = recentClients.filter(client =>
                    client.lastSignInTime && client.lastSignInTime !== 'Unknown' && client.lastSignInTime !== 'Never'
                )

                const loginPercentage = recentClients.length > 0
                    ? ((recentLogins.length / totalClientsToThisPoint.length) * 100).toFixed(2)
                    : 0

                rollingPercentages.push(loginPercentage)

                let rolling5DaySum = 0;
                for (let i = Math.max(0, index - 4); i <= index; i++) {
                    rolling5DaySum += userCountsPerDay[sortedDates[i]] || 0
                }
                rolling5DayCounts.push(rolling5DaySum / 5) // make it a rolling AVERAGE
            })

            // Convert the data for the chart
            const chartData = {
                labels: sortedDates,
                datasets: [
                    {
                        label: 'Clients Signed Up',
                        data: sortedDates.map(date => userCountsPerDay[date]),
                        fill: false,
                        borderColor: 'rgba(75,192,192,1)',
                        yAxisID: 'y-signups',
                    },
                    {
                        label: 'Rolling 30-Day Login Percentage',
                        data: rollingPercentages,
                        fill: false,
                        borderColor: 'rgba(255,99,132,1)',
                        yAxisID: 'y-percentage',
                    },
                    {
                        label: 'Rolling 5-Day Signups',
                        data: rolling5DayCounts,
                        fill: false,
                        borderColor: 'rgba(54, 162, 235, 1)',
                        yAxisID: 'y-signups',
                        borderDash: [5, 5]
                    },
                ],
            }

            // Create a new chart instance
            chartRef.current = new Chart(canvas, {
                type: 'line',
                data: chartData,
                options: {
                    scales: {
                        'y-signups': {
                            type: 'linear',
                            position: 'left',
                            title: {
                                display: true,
                                text: 'Signups',
                            },
                        },
                        'y-percentage': {
                            type: 'linear',
                            position: 'right',
                            title: {
                                display: true,
                                text: 'Login Percentage',
                            },
                            ticks: {
                                callback: value => `${value}%`, // Format as percentage
                            },
                        },
                    },
                },
            })

            // Cleanup function to destroy the chart when the component is unmounted
            return () => {
                if (chartRef.current) {
                    chartRef.current.destroy()
                }
            };
        } catch (error) {
            console.log("Error in ClientSignUpChart useEffect:", error)
        }
    }, [clients, showFromStart])

    return <canvas id="clientSignUpChart" />
}

