import React, { useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import './SystemReport.css'

const startDate = new Date("2025-04-01T00:00:00Z");  // Used for filtering logic
const formattedStartDate = moment(startDate).format('DD/MM/YYYY');  // Used for display



export default function SystemReport() {

    const [systemInfo, setSystemInfo] = useState("")
    const [eventCounts, setEventCounts] = useState({
        successfulSignups: 0,
        appStoreClicks: 0,
        googleStoreClicks: 0,
        appLandingPages: 0,
        appRedirectPages: 0,
    })

    useEffect(() => {
        getSystemInfo()
    }, [])

    const getSystemInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/system/get-system-info`)
        .then((res) => {
            setSystemInfo(res.data)
        })
        .catch((error) => console.log(error))
    }

    useEffect(() => {
        // Process stats
        if (systemInfo.length === 0) return;

        const counts = {
            successfulSignups: 0,
            appStoreClicks: 0,
            googleStoreClicks: 0,
            appLandingPages: 0,
            appRedirectPages: 0,
        }

        systemInfo.forEach(event => {
            const eventDate = new Date(event.occurredAt)
            if (eventDate >= startDate) {
                const eventSummary = event.eventSummary

                if (eventSummary.startsWith("Successfully created new user")) {
                    counts.successfulSignups++
                } else if (eventSummary.startsWith("Google Play Link Clicked")) {
                    counts.googleStoreClicks++
                } else if (eventSummary.startsWith("App Store Link Clicked")) {
                    counts.appStoreClicks++
                } else if (eventSummary.startsWith("App Landing page")) {
                    if (eventSummary.indexOf("/app?") > 0) counts.appLandingPages++
                } else if (eventSummary.startsWith("App redirect page")) {
                    if (eventSummary.indexOf("/app-redirect?") > 0) counts.appRedirectPages++
                }
            }
        })

        setEventCounts(counts)
    }, [systemInfo])

    // Some stats:
    // 1) Since say 01/04/25 when the activity started and text style settled
    //     - How many occurrences of App Landing Page (that aren't with the say 20
    //       seconds of each other. Observerd two that were identical 10 seconds apart
    //       sometimes people press the wrong one so could discard one if theres another directly with it)
    //     - Also add app-redirect later in the stats. Redirect has no text, no clicks but
    //       might open the app directly if it's already present which will negatively skew download rates
    //     - How many occurrences of App Store Link Clinked and Google Play Link Clicked
    //     - And combined to give percentage of arrivals
    //    Note:
    //    Sometimes we seem to get 1 or even both  app/play click but no download and sometimes
    //    no click despite a download also, add in signups, when we see, user has signed up
    //    Some page hits might also be from dev and or marketing causing a reduction in real figures
    //
    //    Improvements TODO:
    //     - Use the percentages by day or graph to allow correlation with marketing changes
    //

    return (
        <div id='system-report-page'>

            {/* Event Count Summary */}
            <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>
                Hit/Signup Stats Since {formattedStartDate}
            </h3>

            <div style={{
                padding: '15px',
                backgroundColor: '#f8f9fa',
                borderRadius: '8px',
                marginBottom: '15px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px'  // Added gap for better spacing
            }}>
                {/* Landings */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px'  // Space between columns
                }}>
                    <div><strong>App Landing Pages:</strong> {eventCounts.appLandingPages}</div>
                    <div><strong>App Redirect Pages:</strong> {eventCounts.appRedirectPages}</div>
                </div>

                {/* Clicks */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                    marginTop: '10px'
                }}>
                    <div><strong>Google Play Clicks:</strong> {eventCounts.googleStoreClicks}</div>
                    <div><strong>App Store Clicks:</strong> {eventCounts.appStoreClicks}</div>
                </div>

                {/* Signups */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                    marginTop: '10px'
                }}>
                    <div><strong>Successful Signups:</strong> {eventCounts.successfulSignups}</div>
                </div>

                {/* Conversion Rates */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                    marginTop: '10px',
                    borderTop: '1px solid #ddd',
                    paddingTop: '10px'
                }}>
                    <div>
                        <strong>Click Rate (Clicks/Lands):</strong> {(eventCounts.appLandingPages + eventCounts.appRedirectPages) > 0
                            ? ((eventCounts.googleStoreClicks + eventCounts.appStoreClicks) / (eventCounts.appLandingPages + eventCounts.appRedirectPages) * 100).toFixed(2) + '%'
                            : 'N/A'}
                    </div>
                    <div>
                        <strong>Signup Rate (Signups/Clicks):</strong> {(eventCounts.googleStoreClicks + eventCounts.appStoreClicks) > 0
                            ? (eventCounts.successfulSignups / (eventCounts.googleStoreClicks + eventCounts.appStoreClicks) * 100).toFixed(2) + '%'
                            : 'N/A'}
                    </div>
                    <div>
                        <strong>Signup Rate (Signups/Lands):</strong> {(eventCounts.appLandingPages + eventCounts.appRedirectPages) > 0
                            ? (eventCounts.successfulSignups / (eventCounts.appLandingPages + eventCounts.appRedirectPages) * 100).toFixed(2) + '%'
                            : 'N/A'}
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ flex: '1', marginRight: '10px' }}>System Report - Events and Errors</h2>
            </div>
            <table id='system-report-table'>
                <thead>
                    <tr id='thead-tr'>
                        <th>Date</th>
                        <th>Event Type</th>
                        <th>Summmary</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {systemInfo.length < 1 ? (
                        <tr>
                            <td colSpan={6} style={{textAlign:'center'}}>
                                No events or errors have been recorded
                            </td>
                        </tr>
                    ) : (
                        systemInfo
                            .sort((a, b) => new Date(b.occurredAt) - new Date(a.occurredAt))
                            .map((event, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ whiteSpace: 'nowrap' }}>{moment(event.occurredAt).format('DD/MM/YY HH:mm:ss')}</td>
                                        <td>{event.eventType}</td>
                                        <td>{event.eventSummary}</td>
                                        <td>{event.eventDetails}</td>
                                    </tr>
                                );
                            })
                    )}
                </tbody>
            </table>
        </div>
    )

}