import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './QuoteClientContainer.css'
import tick from '../../Assets/Icons/tick.png'
import YouTube from 'react-youtube';
import { ExtractEmbedFromVideoUrl } from '../../Utilities.js'
import axios from 'axios'

export default function QuoteClientContainer({ quote, i, selectedQuote, setSelectedQuote, setQuote }) {

    const [expanded, setExpanded] = useState(false)
    const [act, setAct] = useState("")

    useEffect(() => {
        if (quote) {
            getActInfo()
        }
    }, [quote])

    const getActInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/acts/get-act-by-id`, {actId: quote.selectedActId})
        .then((res) => {
            setAct(res.data);
        })
        .catch((error) => console.log(error))
    }

    const opts = {
        width: '250',
        height: '175',
        playerVars: {
            controls: 1,
            modestbranding: 1,
            rel: 0,
            fs: 1,
        },
    };

    const requestQuoteRefresh = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/request-quote-refresh`, {
            artistId: quote.artistId,
            requestId: quote.requestId
        })
        .then(() => {
            alert('Refresh request has been sent to the artist.');

            console.log("Request successfully sent to artist to confirm the quote is still valid.")
        })
        .catch((error) => {
            console.log("Error during operation to request quote refresh", error)
        })
    }

  return (

    <div
        className={selectedQuote === quote._id ? 'quote-option-container-highlighted hover' : 'quote-option-container hover'}
        style={{position:'relative', zIndex: 3}}
        onClick={ () =>{ setSelectedQuote(quote._id); setQuote(quote) }}
    >
        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '10px 20px 0 20px'}}>
            <p style={{
                fontWeight: 700,
                color: '#EBA7A2',
                textTransform:'uppercase',
                fontSize: 16,
                letterSpacing: 2,
                margin: '10px 0'}}
            >
                Option {i+1} {quote.hasPaid && ' - Congratulations, this act is booked!'}
            </p>

            {quote?.expiration?.expired === false &&
                <div className='quote-highlight-circle'>
                    {selectedQuote === quote._id && <img src={tick} style={{height: 22}}/>}
                </div>
            }
        </div>

        {!(quote?.expiration?.expired === false) &&
            <div style={{ marginLeft: '20px' }}>
                <p
                    style={{ color: '#EBA7A2', fontSize: 14, fontWeight: 900, margin: '5px 0'}}
                >
                    This quote has expired. Press below to prompt the artist to reconfirm it.
                </p>

                <button
                    onClick={() => requestQuoteRefresh()}
                    className='fdm-btn'
                    style={{
                        borderRadius: 25,
                        borderWidth: 1,
                        padding: '3px 10px',
                        width: 'auto',
                        minWidth: '120px',
                        marginBottom: '10px',
                        fontSize: '14px',
                    }}
                >
                Request Quote Refresh
                </button>
            </div>
        }

        {/* Quote Expiration Countdown */}
        {quote?.expiration?.refreshedAt && !quote?.expiration?.expired && (() => {
            const daysLeft = Math.max(0, 7 - Math.floor((Date.now() - new Date(quote?.expiration?.refreshedAt)) / (1000 * 60 * 60 * 24)))

            return (
                <p style={{ marginLeft: '20px', color: '#EBA7A2', fontSize: 14, fontWeight: 700, marginTop: '5px' }}>
                    {daysLeft === 0
                        ? "This quote expires today."
                        : `This quote expires in ${daysLeft} day${daysLeft > 1 ? 's' : ''}.`}
                </p>
            )
        })()}

        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '0 20px'}}>
            <p style={{fontWeight: 900, color: '#313131', fontSize: 20, margin: 0}}>{quote.quoteName}</p>
            <p style={{fontWeight: 900, color: '#313131', fontSize: 20, margin: 0}}>£{(quote.customerGross * 1).toFixed(2)}</p>
        </div>

        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '0 20px 10px 20px'}}>
            <p
                className='hover'
                style={{ color: '#EBA7A2', fontSize: 14, textDecoration: 'underline', fontWeight: 700, margin: '10px 0'}}
                onClick={(e) =>{
                    e.stopPropagation()
                    setExpanded(!expanded)
                }}
            >
                See {expanded? 'less' : 'more'}
            </p>

            <Link
                onClick={(e) => e.stopPropagation()}
                to={`/find/artists/${quote.selectedActId}`}
                target="_blank" rel="noopener noreferrer"
                style={{ color: '#EBA7A2', fontSize: 14, textDecoration: 'underline', fontWeight: 700, margin: '10px 0'}}
            >
                View act
            </Link>

        </div>

        <div className={!expanded ? 'artist-quote-minimised' : act.video? 'artist-quote-expanded-with-video' :'artist-quote-expanded'  }>

            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div>
                    <p className='quote-expanded-header'>Act</p>
                    <p>{quote.selectedActName}</p>

                    <div>
                        <p className='quote-expanded-header'>Description</p>
                        <p className='quote-expanded-txt' style={{paddingRight: 10, fontSize: 14, maxHeight: 70, overflow: 'scroll'}}>{quote.description ? quote.description : 'No description added'}</p>
                    </div>
                </div>

                <div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'end'}}>
                        <p className='quote-expanded-header'>Amount</p>
                        <p className='quote-expanded-txt'>£{(quote.customerGross * 1).toFixed(2)}</p>
                    </div>

                    <div style={{display:'flex', alignItems:'center', justifyContent: 'end'}}>
                        <p className='quote-expanded-header' style={{fontWeight: 900}}>Total</p>
                        <p className='quote-expanded-txt' style={{fontWeight: 900}}>£{(quote.customerGross *1).toFixed(2)}</p>
                    </div>
                </div>
            </div>

            {act.video && (
                <div>
                    <YouTube
                        videoId={ExtractEmbedFromVideoUrl(act.video)}
                        opts={opts}
                        onPlay={(e) => e.target.setPlaybackQuality('small')}
                    />
                </div>
            )}

        </div>
    </div>
  )
}
