import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import img1 from '../../Assets/app_1.webp'
import apple from '../../Assets/Icons/appstore.png'
import android from '../../Assets/Icons/googleplay.png'
import planner from '../../Assets/Icons/wedding-planner.png'
import solo from '../../Assets/Icons/singer.png'
import guests from '../../Assets/Icons/guests.png'
import checklist from '../../Assets/Icons/checklist.png'
import group from '../../Assets/app-group.webp'
import './AppLandingPage.css'
import { LogToServer } from '../../Utilities'


// Landing page for access to app stores
// Add both page view and click tracking for this page
// Log all tracking data to server
export default function AppLandingPage() {
  const location = useLocation()
  let tuuid

  const getCookie = (name) => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith(name + '='))
    return cookie ? cookie.split('=')[1] : null
  }

  // Check if the user has a previous tracking UUID and otherwise create one
  const trackingUuid = getCookie("trackingUuid")
  if (!trackingUuid) {
    tuuid = uuidv4()
    document.cookie = `trackingUuid=${uuidv4()}; max-age=max-age=${365 * 24 * 60 * 60}; path=/; SameSite=Lax; Secure`
  } else {
    tuuid = trackingUuid
  }

  useEffect(() => {

    const storeTrackingData = (userAgent) => {

      // Sometimes do not get any pixel stuff (cookies). Several possible reasons
      //  - Happens too quick, hence add delay
      //  - Browser blocking cookies, hence log browser and platform
      //  - Redirect prior to land or first vs subsequent visit
      const fbp = getCookie("_fbp");
      const fbc = getCookie("_fbc");

      const urlParams = new URLSearchParams(location.search);
      const fbclid = urlParams.get('fbclid')
      const utm_source = urlParams.get('utm_source')
      const utm_id = urlParams.get('utm_id')
      const utm_medium = urlParams.get('utm_medium')
      const utm_campaign = urlParams.get('utm_campaign')
      const utm_content = urlParams.get('utm_content')
      const utm_term = urlParams.get('utm_term')

        const eventData = {
          eventName: 'Lead',
          pixelData: {
            fbp,
            fbc,
            fbclid,
          }
        }

        const eventSubCategory = 'AppStoresLandingPage'

        const customData = {
          eventSubCategory: eventSubCategory,
          utmParams: {
            utm_source,
            utm_id,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term,
          }
        }

        const trackingData = {
          trackingUuid: tuuid,
          //deviceFingerprint
          deviceSpec: userAgent,
          fbp,
          fbc,
          utmParams: customData.utmParams,
          arrivalUrl: '/app',
          //deeplink
          //events: {
        }

      axios.post(`${process.env.REACT_APP_API}/users/log-browser-tracking-info`, { eventData, customData, trackingData })
      .then(() => {
          // Just temporary - occurs twice?
          LogToServer(`Successfully logged tracking data from /app page ${tuuid}`)

        if (process.env.NODE_ENV === "development") {
          console.log("Successfully logged tracking data")
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Error logging tracking data", error)
        }
      })
    }

    const getDeviceType = (userAgent) => {
      let deviceType = "Desktop"

      if (/android/i.test(userAgent)) {
        deviceType = "Android"
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        deviceType = "iOS"
      }

      return deviceType
    }

    // Track pixel and ad info
    if (typeof window !== "undefined") {
      setTimeout(() => {
        // Get user agent info
        const userAgent = navigator.userAgent || "Unknown"

        const trackingUuid = getCookie("trackingUuid")
        if (!trackingUuid) {
          storeTrackingData(userAgent)
        }

        const deviceType = getDeviceType(userAgent)
        LogToServer(`App Landing page. Arrival URL: ${window.location.href} | Device: ${deviceType}`)

        if (typeof window.fbq !== "undefined") {
          // Note: despite full URL explicitly here, the full URL is not showing in the event manager,
          // only the domain is. Not clear if that's just how it is or if it's possible to show it
          // Note that this is also included in the delay to ensure fbq is ready
          window.fbq("track", "PageView", {
            url: location.pathname + location.search,
            page_url: window.location.href,
          })
        }
      }, 500)

    }
  }, [])

  useEffect(() => {
    // TBD Determine whether everything is running twice in prod
    LogToServer(`Handle click use effect`)

    // Only execute on the /app landing page
    const handleClick = (e) => {
      LogToServer(`App landing page click detected`)

      let target = e.target

      // If the clicked element is an image inside a link, get the parent <a>
      if (target.tagName === "IMG") {
        target = target.closest("a")
      }

      if (!target || !target.id) {
        return
      }

      if (!window.fbq) {
         // Prevent errors if fbq is undefined
        return
      }

      if (target.id === "app-store-link") {
        LogToServer(`App Store Link Clicked" ${target.href}`)

        window.fbq("track", "Lead", {
          content_name: "App Store",
          content_category: "App Download",
          content_ids: ["firstdance_ios_app"],
          destination: target.href,
        })
      }

      if (target.id === "google-play-link") {
        LogToServer(`Google Play Link Clicked" ${target.href}`)

        window.fbq("track", "Lead", {
          content_name: "Google Play",
          content_category: "App Download",
          content_ids: ["firstdance_android_app"],
          destination: target.href,
        })
      }
    }

    // Attach the event listener to the whole document
    document.addEventListener("click", handleClick)

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])


  // Note:
  // No deeplink used for now from this page to attempt app open like in AppRedirect because we fully
  // expect no app installed if we land here
  // In any case if it went to the store and the app was installed it would show open rather tham install
  // The question then would be whether or not the parameter in the app store URL persists as a deeplink

  return (
    <div>
      <div className='app-download-container'>
        <div className='app-download-info'>
          <p id='app-header-txt'>Download our app today and start planning your big day!</p>
          <p style={{color: '#1e1e1e', fontWeight: 600, fontSize: 20}}>Our all-in-one wedding planning app is here to make your dream day effortless. From scheduling your big day to finding the perfect wedding music!</p>
          <div>
            <a
              id="app-store-link"
              href={`https://apps.apple.com/uk/app/first-dance/id6739738754?tuuid=${tuuid}`}
              target='_blank'
            >
              <img
                className='app-icon'
                style={{ marginRight: 10 }}
                src={apple}
                alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'
              />
            </a>
            <a
              id="google-play-link"
              href={`https://play.google.com/store/apps/details?id=com.firstdance.firstdance&tuuid=${tuuid}`}
              target='_blank'
            >
              <img
                className='app-icon'
                src={android}
                alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'
              />
            </a>
          </div>
        </div>
        <img className='app-preview' src={img1} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
      </div>

      <div style={{ backgroundColor: '#FCE0E0'}} >
        <div id='why-download'>
          <h2>Why Download Our App?</h2>
          <div id='app-features'>
            <div className='app-feature-container'>
              <img className='app-feature-icon' src={planner} alt='First Dance Music | Free Wedding Planner App Feature'/>
              <div>
                <h3>Day Planner & Scheduler</h3>
                <p>Map out every minute of your wedding day with ease! Keep your timeline organised so you can relax and enjoy your special moments.</p>
              </div>
            </div>
            <div className='app-feature-container'>
              <img className='app-feature-icon' src={solo} alt='First Dance Music | Free Wedding Planner App Feature'/>
              <div>
                <h3>Find Perfect Wedding Music</h3>
                <p>Discover the right musicians for your wedding day. Swipe through profiles, watch videos, and get instant quotes. Love their vibe? Chat with them directly to make arrangements.</p>
              </div>
            </div>
            <div className='app-feature-container'>
              <img className='app-feature-icon' src={guests} alt='First Dance Music | Free Wedding Planner App Feature'/>
              <div>
                <h3>Guest List & Seating Plan</h3>
                <p>Manage RSVPs and create seating arrangements all in one place—no spreadsheets, no confusion.</p>
              </div>
            </div>
            <div className='app-feature-container'>
              <img className='app-feature-icon' src={checklist} alt='First Dance Music | Free Wedding Planner App Feature'/>
              <div>
                <h3>Comprehensive Checklist</h3>
                <p>From sending invites to booking vendors, our app guides you with a checklist tailored to every stage of your wedding planning journey.</p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className='app-download-container' style={{justifyContent:'center'}}>
        <div className='app-download-info'>
          <p style={{color: '#1e1e1e', fontWeight: 600, fontSize: 24, margin: 0}}>Your dream wedding starts here.</p>
          <p style={{color: '#1e1e1e', fontWeight: 900, fontSize: 24, marginTop: 0}}>Download the app today!</p>
          <div>
            <a href='https://apps.apple.com/uk/app/first-dance/id6739738754' target='_blank'>
              <img className='app-icon' style={{marginRight: 10}} src={apple} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.firstdance.firstdance' target='_blank'>
              <img className='app-icon' src={android} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
            </a>
          </div>
        </div>
        <img className='app-images' src={group} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
      </div>
    </div>
  )
}
