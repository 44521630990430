import React, { useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import hearts from '../../Assets/how-it-works.png'
import { LogToServer } from '../../Utilities'

export default function AppRedirect() {
  const location = useLocation()

    useEffect(() => {
      // Track PageView on route change
      if (typeof window !== "undefined" && typeof window.fbq !== "undefined") {
        LogToServer(`App redirect page - href: ${window.location.href} Pathname: ${location.pathname} Search: ${location.search} `)

        // TBD TODO: the full URL is not showing in the event manager, just the domain
        window.fbq("track", "PageView", {
          url: location.pathname + location.search,
          page_url: window.location.href, // Explicitly send the full URL
        })
      }
    }, [location])

    useEffect(() => {
        const userAgent = navigator.userAgent || window.opera;
        const appDeepLink = "firstdance://";
        const androidStoreLink = "https://play.google.com/store/apps/details?id=com.firstdance.firstdance";
        const iosStoreLink = "https://apps.apple.com/uk/app/first-dance/id6739738754";
        const fallback = "https://firstdancemusic.co.uk/app"

        // Delay the app redirect slightly to allow FB tracking
        const delayRedirect = setTimeout(() => {
          // Attempt to open the app
          window.location.href = appDeepLink;
        }, 500)

        // If the app is not installed, redirect to the store after a delay
        const timeout = setTimeout(() => {
          if (/android/i.test(userAgent)) {
            window.location.href = androidStoreLink;
          } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = iosStoreLink;
          } else {
            window.location.href = fallback;
          }
        }, 2500)

        return () => {
          clearTimeout(delayRedirect)
          clearTimeout(timeout)
        }
      }, [])

      return(
        <div style={{display:'flex', height: '100vh', justifyContent:'center', alignItems: 'center'}}>
            <img src={hearts} style={{position:'absolute',top: 50, width: '100vw'}}/>
            <p style={{fontWeight: 700, color: '#1b1b1b'}}>Redirecting to the app...</p>
        </div>
      )
}
